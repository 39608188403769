import React, { useContext, useEffect, useState } from 'react'
import './styles/SamvaadiniV2Styles.css';
import { Avatar, Button, Divider, Input, notification, Popover } from 'antd';
import Navbar from './Navbar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import SessionCreationCard from './components/SessionCreationCard';
import SamvaadiniAdvertisement from './components/SamvaadiniAdvertisement';
import PlusIcon from '../../static/svg/PlusIcon';

import { getSessionsLandingScreenSummary } from '../../service/SamvadiniApiService';
import moment from 'moment';
import { AppContext } from '../../App';
import SessionListSkeleton from './skeleton/SessionListSkeleton';
import SessionCreationFloaterButton from './components/SessionCreationFloaterButton';
import CONSTANTS from '../../constants/constants';
import { getTime } from '../../constants/utils';

function SamvaadiniMainContainer() {
    const history = useHistory();
    const [activeSessionDetailsTab, setActiveSessionDetailsTab] = useState('currentSession'); // 'currentSession' | 'pastSession'
    const [searchTerm, setSearchTerm] = useState('');
    const {mitraReducer} = useContext(AppContext);
    const [currentSessionDetails, setCurrentSessionDetails] = useState(null);
    const [pastSessionDetails, setPastSessionDetails] = useState(null);
    const [isSessionCreationAllowed, setIsSessionCreationAllowed] = useState(false);
    const [loading,setLoading] = useState(true);

    const sessionCreationOptions = [...CONSTANTS.SAMVAADINI_SESSION_CREATION_OPTIONS];

    const goToSessionDetailsScreen = () => {
        history.push(`/samvadini-session-details?sessionDetails=${JSON.stringify(currentSessionDetails)}`);
    }

    const getLandingScreenInformation = () => {

        getSessionsLandingScreenSummary().then(res=> {
            console.log(res);
            if(res?.data?.landingPageData?.currentSession?.intentCallingSessionId) {
                setCurrentSessionDetails(res?.data?.landingPageData?.currentSession);
            }

            if(res?.data?.landingPageData?.pastSession?.intentCallingSessionId) {
                setPastSessionDetails(res?.data?.landingPageData?.pastSession);
            }

            if(res?.data?.landingPageData?.newSessionCreationAllowed) {
                setIsSessionCreationAllowed(true);
            } else {
                setIsSessionCreationAllowed(false);
            }
            setLoading(false);
        }).catch(err=> {
            setLoading(false);
            console.log(err);
            notification['error']({
                message: 'Something went wrong, please try again later.'
            })
        })
    }

    useEffect(() => {
      getLandingScreenInformation();
    }, [])

    if(loading) {
        return (
            <SessionListSkeleton />
        )
    }
    
  return (
    <div className='gray-main-container'>
        {/* <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/> */}

        {
            searchTerm ? null: (
                <>
                    <SamvaadiniAdvertisement />
                    
                    <div className="session-details-entrypoint-container">
                        <div className="session-tab-container">
                            <div 
                            className={`single-tab ${activeSessionDetailsTab === 'currentSession' ? 'active-tab':''}`}
                            onClick={()=>setActiveSessionDetailsTab('currentSession')}
                            > 
                                {
                                    activeSessionDetailsTab === 'currentSession' ? <div className="active-dot"></div> : null
                                }
                                
                                Current Session
                            </div>

                            <div 
                            className={`single-tab ${activeSessionDetailsTab != 'currentSession' ? 'active-tab':''}`}
                            onClick={()=> {
                                setActiveSessionDetailsTab('pastSession');
                                // history.push('/samvadini-session-list');
                            }}
                            >
                                Past Sessions
                            </div>
                        </div>

                        {
                            activeSessionDetailsTab === 'currentSession'  ? (
                                currentSessionDetails?.intentCallingSessionId ? (
                                <div className="session-details-container">
                                    <div className="left-details-section">
                                        <img src="/image/session-details-yellow.png" className="tab-section-image" />
                                        <div className="field-container">
                                            <span className='details-header'>Created</span>
                                            <span className='details-sub-header'>
                                                {
                                                    currentSessionDetails?.submittedAt ? getTime(currentSessionDetails?.submittedAt): null
                                                }
                                            </span>
                                        </div>
                                        <div className="field-container">
                                            <span className='details-header'>Uploaded</span>
                                            <span className='details-sub-header'>{currentSessionDetails?.leadsUploaded}</span>
                                        </div>
                                        <div className="field-container">
                                            <span className='details-header'>Connected</span>
                                            <span className='details-sub-header'>{currentSessionDetails?.callsConnected}</span>
                                        </div>
                                        <div className="field-container">
                                            <span className='details-header'>Interested</span>
                                            <span className='details-sub-header'>{currentSessionDetails?.interestedLeadCount}</span>
                                        </div>
                                    </div>
                                    <div className="right-button-section">
                                        <Button className='view-btn' onClick={()=>goToSessionDetailsScreen()}>View</Button>
                                    </div>
                                </div>):(
                                <div className="no-active-sessions-container">
                                    <div className="no-active-session-desc">
                                        <img src="/image/mobile-no-session.png" className='mobile-no-session' />
                                        {
                                            mitraReducer?.mitraInfo?.managerMitraID ? (
                                                <>
                                                    <span className='no-active-session-header'>No Active Session</span>
                                                </>
                                            ):(
                                                <>
                                                    <span className='no-active-session-header'>Create session</span>
                                                    <span className='no-active-sub-header'>You have no live session. <span className="start-session-indication"></span></span>
                                                </>
                                            )
                                        }
                                        
                                    </div>
                                </div>)
                            ): (
                                pastSessionDetails?.intentCallingSessionId ? (
                                    <div className="session-details-container">
                                    <div className="left-details-section">
                                        <img src="/image/past-session.png" className="tab-section-image" />
                                        <div className='d-flex align-items-start flex-wrap' style={{gap: '16px'}}>
                                            <div className="field-container">
                                                <span className='details-header'>Created</span>
                                                <span className='details-sub-header'>
                                                    {
                                                        pastSessionDetails?.submittedAt ? moment(pastSessionDetails?.submittedAt).format('DD/MM/YYYY'): null
                                                    }
                                                </span>
                                                <span className='details-sub-header' style={{fontSize:'12px'}}>
                                                    {
                                                        pastSessionDetails?.submittedAt ? moment(pastSessionDetails?.submittedAt).format('hh:mm a'): null
                                                    }
                                                </span>
                                            </div>
                                            <div className="field-container">
                                                <span className='details-header'>Uploaded</span>
                                                <span className='details-sub-header'>{pastSessionDetails?.leadsUploaded}</span>
                                            </div>
                                            <div className="field-container">
                                                <span className='details-header'>Connected</span>
                                                <span className='details-sub-header'>{pastSessionDetails?.callsConnected}</span>
                                            </div>
                                            <div className="field-container">
                                                <span className='details-header'>Interested</span>
                                                <span className='details-sub-header'>{pastSessionDetails?.interestedLeadCount}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right-button-section">
                                        <Button className='view-btn' style={{width:'238px'}} onClick={()=>history.push('/samvadini-session-list')}>View all Sessions</Button>
                                    </div>
                                </div>
                                ):(
                                    <div className="no-active-sessions-container">
                                        <div className="no-active-session-desc">
                                            <img src="/image/mobile-no-session.png" className='mobile-no-session' />

                                            {
                                                mitraReducer?.mitraInfo?.managerMitraID ? (
                                                    <>
                                                        <span className='no-active-session-header'>No Session</span>
                                                    </>
                                                ):(
                                                    <>
                                                        <span className='no-active-session-header'>No session</span>
                                                        <span className='no-active-sub-header'>You do not have any past sessions . <span className="start-session-indication"></span></span>
                                                    </>
                                                )
                                            }
                                            
                                        </div>
                                    </div>
                                )
                            )
                        }
                        
                    </div>

                        {
                            mitraReducer?.mitraInfo?.managerMitraID ? null: (
                                <div className="session-creation-options-container">
                                    <div className="session-creation-header">Start New Session</div>
                                    <div className="session-creation-options">

                                        {
                                            sessionCreationOptions.map(item => {
                                                return <SessionCreationCard 
                                                key={item.id}
                                                props={item}
                                                isSessionCreationAllowed={isSessionCreationAllowed}
                                                />
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                </>
            )
        }

        <SessionCreationFloaterButton 
        isSessionCreationAllowed={isSessionCreationAllowed}
        />
    
    </div>
  )
}

export default SamvaadiniMainContainer