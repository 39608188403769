import React, { useState, useEffect, useRef } from 'react'
import '../../style/BulkReferralStyle.scss'
import SideSelector from "../../../common/siderSelector";
import { DeleteOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { getpopularCities, getClientsForBulkReferral } from './../../../../service/bulkActionService'
import * as homeService from './../../../../service/homeService';
import { Divider, Button } from 'antd';

const BulkReferralStepOne = ({
    selectedCityFromParent,
    selectedJobCategoryFromParent,
    selectedClientFromParent,
    selectedJobLocationFromParent,
    returnStepOneCity,
    returnStepOneJobCategory,
    returnStepOneClient,
    returnStepOneJobLocation,
    returnStepOneAvailableJobs,
    goToNextStep,
    isAiFilteringEnabled, // this is the variable used to tweak FE views
    setIsAiFilteringEnabled,
    enableAIFiltering, // this is the query param
    setWithoutReferral,
    clearedStep }) => {
    const siderSelectRef = useRef();

    const [cityList, setCityList] = useState([]);
    const [selectedCity, setSelectedCity] = useState(selectedCityFromParent)
    const [cityError, setCityError] = useState(false)

    const [allClientListAcrossCategories, setAllClientListAcrossCategories] = useState({});
    const [clientList, setClientList] = useState([]);
    const [clientError, setClientError] = useState(false)
    const [selectedClient, setSelectedClient] = useState(selectedClientFromParent)

    const [jobCategoryList, setJobCategoryList] = useState([])
    const [jobCategoryError, setJobCategoryError] = useState(false)
    const [selectedJobCategory, setSelectedJobCategory] = useState(selectedJobCategoryFromParent)


    const [jobLocationList, setJobLocationList] = useState([])
    const [jobLocationError, setJobLocationError] = useState(false)
    const [selectedJobLocation, setSelectedJobLocation] = useState(selectedJobLocationFromParent)

    const [availableJobsList, setAvailableJobsList] = useState([])
    const [selectedClientExcludedFromAIFiltering, setSelectedClientExcludedFromAIFiltering] = useState(false);


    useEffect(() => {
        getpopularCities().then((response) => {
            setCityList(response.data.premiumCities)
        })

        if (selectedCityFromParent) {
            fetchClientsAndCategoriesForSelectedCity(selectedCityFromParent, true)
        }

        if (selectedCityFromParent && selectedClientFromParent) {
            getJobLocations(selectedCityFromParent, selectedClientFromParent);
        }

    }, [])

    const setAndReturnVal = (valFor = "", setVal) => {
        switch (valFor) {
            case "city":
                setSelectedCity(setVal);
                returnStepOneCity(setVal);
                break;
            case "category":
                setSelectedJobCategory(setVal);
                returnStepOneJobCategory(setVal);
                break;
            case "client":
                setSelectedClient(setVal);
                returnStepOneClient(setVal);
                break;
            case "location":
                setSelectedJobLocation(setVal);
                returnStepOneJobLocation(setVal);
                break;
        }
    }

    const handleCityChange = (city) => {
        setCityError(false);
        setAndReturnVal("city", city)
        setAndReturnVal("category", null)
        setJobCategoryList([])
        setAndReturnVal("client", null)
        setClientList([])
        setAndReturnVal("location", null)
        setJobLocationList([])
        fetchClientsAndCategoriesForSelectedCity(city, false)
        returnStepOneCity(city)
    }

    const fetchClientsAndCategoriesForSelectedCity = (city, updateJobCategoryField = true) => {
        if (city) {
            getClientsForBulkReferral(city).then((result) => {
                if (result && result.data.viewData.companies) {
                    const companyAndCategories = result.data.viewData.companies;
                    setJobCategoryList(Object.keys(companyAndCategories));
                    setAllClientListAcrossCategories(result.data.viewData.companies)
                    if (selectedJobCategoryFromParent && updateJobCategoryField) {
                        handleJobCategoryChange(selectedJobCategoryFromParent, true, result.data.viewData.companies)
                    }
                }
            });
        }
    };

    const handleClientChange = (client) => {
        if(enableAIFiltering === 'true') {
            // showing client exclusion only if AI Filtering is enabled
            if(['rapido', 'zepto', 'uber'].includes(client?.toLowerCase())) {
                setSelectedClientExcludedFromAIFiltering(true);
                setIsAiFilteringEnabled(false);
            } else {
                setSelectedClientExcludedFromAIFiltering(false);
                setIsAiFilteringEnabled(true);
            }
        }
        setClientError(false)
        setAndReturnVal("client", client)
        setAndReturnVal("location", null)
        getJobLocations(selectedCity, client);
    }

    const handleJobCategoryChange = (category, isFromParent = false, clientData = {}) => {
        setJobCategoryError(false);
        setAndReturnVal("category", category)
        if (!isFromParent) {
            setAndReturnVal("client", null)
            setAndReturnVal("location", null)
            setJobLocationList([])
        }
        if (isFromParent) {
            setClientList(clientData[category])
        }
        else {
            setClientList(allClientListAcrossCategories[category])
        }
    }

    const getJobLocations = (city, company) => {
        if (city === 'Work from Home') {
            setJobLocationList(['Work from Home']);
        } else {
            if (city && company) {
                homeService.getJobLocations(city, company).then((result) => {
                    if (result && result.data.viewData.jobLocations) {
                        let jobLocations = result.data.viewData.jobLocations;
                        jobLocations = jobLocations.map((c) => c.name);
                        setJobLocationList(jobLocations);
                    }
                });
            }
        }
    };

    const handleJobLocationChange = (jobLocation) => {
        setJobLocationError(false);
        setAndReturnVal("location", jobLocation)
        getJobs(selectedJobCategory, selectedCity, selectedClient, jobLocation);
        setJobLocationError(false)
    }

    const getJobs = (category, city, companyName, jobLocation) => {
        if (city && category && companyName && jobLocation) {
            homeService.getJobs(category, city, companyName, jobLocation).then((result) => {
                if (result && result.data.viewData.jobs) {
                    setAvailableJobsList(result.data.viewData.jobs);
                    returnStepOneAvailableJobs(result.data.viewData.jobs)
                }
            });
        }
    };

    const continueWOReferral = () => {
        setWithoutReferral(true);
        goToNextStep()
    }

    const checkIfNextDisabled = () => {
        let isDisabled = false
        if (!selectedCity || !selectedJobCategory || !selectedClient || !selectedJobLocation) {
            isDisabled = true
        }

        return isDisabled;
    }

    const hasEmptyField = () => {
        if (!selectedCity) {
            setCityError(true);
            return true;
        }

        if (!selectedClient) {
            setClientError(true)
            return true
        }

        if (!selectedJobCategory) {
            setJobCategoryError(true)
            return true
        }

        if (!selectedJobLocation) {
            setJobLocationError(true)
            return true
        }
    }

    const onNextClick = () => {
        if (!hasEmptyField()) {
            goToNextStep()
        }
    }

    return (
        <div className='br-step-parent br-step-one-dropdowns-parent d-flex justify-content-between'>
            <div className='br-step-dropdown-fields'>
                <div className='br-step-one-section justify-content-between'>
                    <div className="br-step-one-dropdown">
                        <SideSelector
                            ref={siderSelectRef}
                            labelname="City"
                            selectorData={cityList}
                            error={{ status: cityError, text: 'City is required' }}
                            placeholder="Select City"
                            required
                            onChange={(city) => { handleCityChange(city) }}
                            autoFocus
                            selectedValue={selectedCity}
                        />
                    </div>

                    <div className="br-step-one-dropdown">
                        <SideSelector
                            labelname="Job Category"
                            selectorData={jobCategoryList}
                            error={{ status: jobCategoryError, text: 'Job Category is required' }}
                            placeholder="Select Job Category"
                            required
                            onChange={(e) => { handleJobCategoryChange(e) }}
                            disabled={jobCategoryList.length == 0 && !selectedJobCategory}
                            selectedValue={selectedJobCategory}
                        />

                    </div>
                </div>

                <div className='br-step-one-section justify-content-between'>
                    <div className='d-flex flex-column br-step-one-dropdown'>
                        <div className="br-step-one-dropdown w-100">
                            <SideSelector
                                labelname="Client Name"
                                selectorData={clientList}
                                error={{ status: clientError, text: 'Client name is required' }}
                                placeholder="Select Client"
                                required
                                onChange={(e) => { handleClientChange(e) }}
                                disabled={clientList && clientList.length == 0 && !selectedClient}
                                selectedValue={selectedClient}
                                modifiedClassName={enableAIFiltering === 'true' && selectedClientExcludedFromAIFiltering ? 'error-border': ''}
                            />
                        </div>
                        {
                            enableAIFiltering === 'true' && selectedClientExcludedFromAIFiltering ? (
                                <span className='regular14-22 red-negative txt-left' style={{marginTop:'-12px', marginBottom: '16px'}}>AI Filtering is currently not available for selected client</span>
                            ): null
                        }
                        
                    </div>

                    <div className="br-step-one-dropdown">
                        <SideSelector
                            labelname="Job Location"
                            selectorData={jobLocationList}
                            error={{ status: jobLocationError, text: 'Job Location is required' }}
                            placeholder="Select Job Location"
                            required
                            onChange={(e) => { handleJobLocationChange(e) }}
                            disabled={jobLocationList.length == 0 && !selectedJobLocation}
                            selectedValue={selectedJobLocation}
                        />
                    </div>
                </div>
            </div>


            <div className='br-step-action-fields d-flex mobile-bottom-fixed'>
                <Divider style={{margin: '4px 0px 16px'}}></Divider>
                <div className='br-step-btns d-flex'>
                    <Button className='br-btn-container br-clear-all-btn d-flex align-items-center justify-content-center' onClick={() => continueWOReferral()}>
                        Continue Without Referral
                        <ArrowRightOutlined className='br-step-btn-img-orange' />
                    </Button>

                    <Button className='br-btn-container br-next-btn d-flex align-items-center justify-content-center text-ellipsis' onClick={() => onNextClick()} disabled={checkIfNextDisabled()}>
                        {
                            enableAIFiltering === 'true' && selectedClientExcludedFromAIFiltering ? <span className='text-ellipsis'>Continue Without AI Filtering</span>: 'Next'
                        }
                        <ArrowRightOutlined className='br-step-btn-img-white' />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default BulkReferralStepOne; 