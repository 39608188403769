import React, { useContext, useEffect, useRef, useState } from 'react'
import Navbar from './Navbar'
import {PlusOutlined,ArrowLeftOutlined,CloudDownloadOutlined,SlidersOutlined, UserOutlined, CopyOutlined,PhoneOutlined} from '@ant-design/icons'
import { Avatar, Button, Divider, Pagination, Popover, Skeleton, notification } from 'antd';
import StatisticCard from './components/StatisticCard';
import { useHistory } from 'react-router-dom';
import InterestedCandidateCard from './components/InterestedCandidateCard';
import SessionCard from './components/SessionCard';
import LeftArrow from '../../static/svg/LeftArrow';
import FoldingHands from '../../static/images/folding-hands.gif';
import './styles/SessionListV2.css'
import FilterIcon from '../../static/svg/FilterIcon';
import { getSessionList, getUploadedListOfFiles, statisticCardInformation } from '../../service/SamvadiniApiService';
import { AppContext } from '../../App';
import PopoverDropdown from './components/PopoverDropdown';
import NewCustomPagination from './components/NewCustomPagination';
import DownArrow from '../../static/svg/DownArrow';
import UpArrow from '../../static/svg/UpArrow';
import SessionListSkeleton from './skeleton/SessionListSkeleton';
import ScrollToTopButton from './components/ScrollToTopButton';
import SessionCreationFloaterButton from './components/SessionCreationFloaterButton';

function SessionListV2() {
    const scrollContainerRef = useRef(null);

    const history = useHistory();
    const {mitraReducer} = useContext(AppContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeSessionDetailsTab, setActiveSessionDetailsTab] = useState('pastSession'); // 'currentSession' | 'pastSession'
    const [sessionListData, setSessionListData] = useState([]);
    const [showFloaterMenu, setShowFloaterMenu] = useState(false);
    const [allSessionsSummary, setAllSessionsSummary] = useState({});

    const [loading, setLoading] = useState(true);
    const [sessionListLoading, setSessionListLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const totalItemsPerPage = 9;

    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState([
        { id: 0, name: 'Client', isActive: false },
        { id: 1, name: 'City',isActive: false },
        { id: 2, name: 'Referral Date', isActive: false }
    ]);

    const [showSessionTypeFilter, setShowSessionTypeFilter] = useState(false);
    const [selectedSessionType, setSelectedSessionType] = useState('All');
    const [sessionTypeFilterItems, setSessionTypeFilterItems] = useState([
        { id: 3, name: 'All', isActive: true },
        { id: 0, name: 'Smart Logic', isActive: false },
        { id: 1, name: 'File Upload',isActive: false },
        { id: 2, name: 'Advance Filter', isActive: false },
        { id: 4, name: 'Bulk Upload', isActive: false }
    ]);

    const [statisticCardData, setStatisticCardData] = useState([]);

    const changeDataOnStatisticCards = (type) => {
        const typeToAPIPropertyMap = {
            'All':'total',
            'Smart Logic': 'SMART_SELECT',
            'File Upload': 'FILE_UPLOAD',
            'Advance Filter': 'ADVANCE_FILTER',
            'Bulk Upload': 'BULK_UPLOAD'
        }

        const statistic = [
            {
                id: 1,
                label: 'Total Session',
                count: null,
                imageUrl: '/image/Time-Machine.png'
            },
            {
                id: 0,
                label: 'Leads Uploaded',
                count: null,
                imageUrl: '/image/add-properties.png'
            },
            {
                id: 2,
                label: 'Interested',
                count: null,
                imageUrl: '/image/done-ai.png'
            }
        ]

        if(type && typeToAPIPropertyMap[type]) {
            if(allSessionsSummary.summaryData?.summary?.totalSessions?.[typeToAPIPropertyMap[type]]) {
                statistic[0].count = allSessionsSummary.summaryData?.summary?.totalSessions?.[typeToAPIPropertyMap[type]];
            }
    
            if(allSessionsSummary.summaryData?.summary?.leadsUploaded?.[typeToAPIPropertyMap[type]]) {
                statistic[1].count = allSessionsSummary.summaryData?.summary?.leadsUploaded?.[typeToAPIPropertyMap[type]];
            }
    
            if(allSessionsSummary.summaryData?.summary?.interestedLeadCount?.[typeToAPIPropertyMap[type]]) {
                statistic[2].count = allSessionsSummary.summaryData?.summary?.interestedLeadCount?.[typeToAPIPropertyMap[type]];
                
                if(allSessionsSummary.summaryData?.summary?.leadsUploaded?.[typeToAPIPropertyMap[type]]) {
                    statistic[2].percentage = ((parseInt(allSessionsSummary.summaryData?.summary?.interestedLeadCount?.[typeToAPIPropertyMap[type]])/parseInt(allSessionsSummary.summaryData?.summary?.leadsUploaded?.[typeToAPIPropertyMap[type]]))*100).toFixed(2);
                }
            }
        }

        setStatisticCardData([...statistic]);
    }

    const getAllSessionList = async() => {
        setSessionListLoading(true);
        try{
            const offset = (currentPage-1)*totalItemsPerPage;
            const payload = {
                type: selectedSessionType === 'Smart Logic' ? 'Smart Select': selectedSessionType,
                limit: totalItemsPerPage,
                offset
            }

            const response = await getSessionList(payload);

            console.log(response?.data?.dashboardPageData?.sessions, 'SESSIONS');

            if(response?.data?.dashboardPageData?.sessions?.length) {
                setSessionListData([...response.data.dashboardPageData?.sessions]);
                setTotalItems(response?.data?.dashboardPageData?.totalSessions || 0);

                setLoading(false)
            } else {
                setSessionListData([]);
                setTotalItems(0);
            }
            setSessionListLoading(false);
        } catch (e) {
            setSessionListData([]);
            setTotalItems(0);

            notification['error'] ({
                message: 'Something went wrong while fetching list of files.'
            });

            setSessionListLoading(false);
        }
    }

    const getSessionLevelSummaryInformation = () => {
        statisticCardInformation().
        then(response=> {

            setAllSessionsSummary(response.data);

            const statistic = [
                {
                    id: 1,
                    label: 'Total Session',
                    count: null,
                    imageUrl: '/image/Time-Machine.png'
                },
                {
                    id: 0,
                    label: 'Leads Uploaded',
                    count: null,
                    imageUrl: '/image/add-properties.png'
                },
                {
                    id: 2,
                    label: 'Interested',
                    count: null,
                    imageUrl: '/image/done-ai.png'
                }
            ]

            if(response?.data?.summaryData?.summary?.totalSessions?.total) {
                statistic[0].count = response?.data?.summaryData?.summary?.totalSessions?.total;
            }

            if(response?.data?.summaryData?.summary?.leadsUploaded?.total) {
                statistic[1].count = response?.data?.summaryData?.summary?.leadsUploaded?.total;
            }

            if(response?.data?.summaryData?.summary?.interestedLeadCount?.total) {
                statistic[2].count = response?.data?.summaryData?.summary?.interestedLeadCount?.total;
                
                if(response?.data?.summaryData?.summary?.leadsUploaded?.total) {
                    statistic[2].percentage = ((parseInt(response?.data?.summaryData?.summary?.interestedLeadCount?.total)/parseInt(response?.data?.summaryData?.summary?.leadsUploaded?.total))*100).toFixed(2);
                }
            }

            setStatisticCardData([...statistic]);
        }).catch(err=> {
            console.log(err);
        })
    }

    useEffect(() => {
        if(mitraReducer?.mitraInfo?.id) {
            getSessionLevelSummaryInformation();
        }
    }, [mitraReducer?.mitraInfo?.id])

    const onSessionTypeFilterSelection = (item) => {
        const modifiedFilterData = [...sessionTypeFilterItems];

        for (let i = 0; i < modifiedFilterData.length; i++) {
            if(modifiedFilterData[i].id === item.id) {
                modifiedFilterData[i].isActive = true;
                setSelectedSessionType(modifiedFilterData[i].name);
                setCurrentPage(1);
                changeDataOnStatisticCards(modifiedFilterData[i].name);
            } else {
                modifiedFilterData[i].isActive = false;
            }
        }

        setSessionTypeFilterItems([...modifiedFilterData]);
    }

    const handlePageChange = (pageNumber) => {
        console.log('Page changed to:', pageNumber);
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getAllSessionList();
    }, [selectedSessionType, currentPage])
    

    const goToLiveSession = () => {
        // change logic
        // if(immutableSessionList.length && immutableSessionList[0] && !immutableSessionList[0].isProcessingCompleted) {
        //     history.push(`/samvadini-session-details?sessionDetails=${JSON.stringify(immutableSessionList[0])}`)
        // } else {
        //     notification['warning']({
        //         message: 'No active session - Create one to filter AI Hot Leads.'
        //     })
        // }
        if(!allSessionsSummary?.summaryData?.summary?.allowToCreateNewSession) {
            const payload = {
                intentCallingSessionId: allSessionsSummary?.summaryData?.summary?.intentCallingSessionId
            }
            const url = `/samvadini-session-details?sessionDetails=${JSON.stringify(payload)}`;
            history.push(url);
        } else {
            notification['warning']({
                message: 'No active session - Create one to filter AI Hot Leads.'
            })
        }
    }

    const createNewSessionBtnDesktop = () => {
        return <div 
        className={`
            ${allSessionsSummary?.summaryData?.summary?.allowToCreateNewSession ? 'create-session-btn':'create-session-btn-disabled'}`}
        onClick={()=>allSessionsSummary?.summaryData?.summary?.allowToCreateNewSession ? history.push('/samvadini-v2'): 1}>
            <PlusOutlined style={{fontSize:'16px',color:"#FFF"}}/> 
            <span className='create-session-btn-text'>Create New Session</span>
        </div>
    }

    const createNewSessionBtnMobile = () => {
        return <div 
        className={`create-session-btn-mobile 
            ${allSessionsSummary?.summaryData?.summary?.allowToCreateNewSession ? 'create-session-btn':'create-session-btn-disabled'}`}
        onClick={()=> allSessionsSummary?.summaryData?.summary?.allowToCreateNewSession ? setShowFloaterMenu(true): 1}>
            <PlusOutlined style={{fontSize:'16px',color:"#FFF"}}/> 
        </div>
    }

    if(loading) {
        return (
            <SessionListSkeleton />
        )
    }

  return (
    <>
        <div className='gray-main-container' ref={scrollContainerRef}>
            {/* <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/> */}

            {
                searchTerm ? null: (
                    <>
                        <div className="back-navigation d-flex align-items-center" onClick={()=>history.push('samvadini-v2')}>
                            <LeftArrow />
                            <span className='back-text'>Back</span>
                        </div>

                        <div className="d-flex justify-content-between align-items-center flex-wrap" style={{margin:'9px 32px 0px', gap: '16px'}}>
                            <span className='lead-list-hero-text d-flex' style={{margin: 0}}>
                                Leads Dashboard
                                <img src={FoldingHands} style={{width: '34px', height:'34px', marginLeft: '12px', marginTop: '-12px'}} />
                            </span>

                            {
                                mitraReducer?.mitraInfo?.managerMitraID ? null :  allSessionsSummary?.summaryData?.summary?.allowToCreateNewSession ? createNewSessionBtnDesktop(): (
                                    <Popover
                                    content={<span className='inter-regular-10-16'>Wait for the current session to finish before starting another.</span>} 
                                    trigger={'hover'}
                                    placement='bottom'
                                    >
                                        { createNewSessionBtnDesktop() }
                                    </Popover>
                                )
                            }
                        </div>

                        <div className="tab-and-btn-container d-flex justify-content-between">
                            <div className="session-tab-container">
                                <div 
                                className={`single-tab ${activeSessionDetailsTab === 'currentSession' ? 'active-tab':''}`}
                                onClick={()=>goToLiveSession()}
                                > 
                                    {
                                        activeSessionDetailsTab === 'currentSession' ? <div className="active-dot"></div> : null
                                    }
                                    
                                    Current Session
                                </div>

                                <div 
                                className={`single-tab ${activeSessionDetailsTab != 'currentSession' ? 'active-tab':''}`}
                                onClick={()=>setActiveSessionDetailsTab('pastSession')}
                                >
                                    Past Sessions
                                </div>
                            </div>

                            {
                                mitraReducer?.mitraInfo?.managerMitraID ? null : allSessionsSummary?.summaryData?.summary?.allowToCreateNewSession ? createNewSessionBtnMobile(): (
                                    <Popover
                                    content={<span className='inter-regular-10-16'>Wait for the current session to finish before starting another.</span>} 
                                    trigger={'hover'}
                                    placement='bottom'
                                    >
                                        { createNewSessionBtnMobile() }
                                    </Popover>
                                )
                            }

                        </div>

                        <div className='d-flex align-items-start justify-content-between filter-section-sl' style={{margin: '20px 32px'}}>
                            <div className='d-flex flex-column align-items-start'>
                                <span className='inter-semibold-12-18 color-252526'>View past sessions</span>
                                <span className='color-838383 inter-regular-10-16'>See all of them now</span>
                            </div>

                            <PopoverDropdown
                            content={sessionTypeFilterItems}
                            visible={showSessionTypeFilter}
                            setVisible={setShowSessionTypeFilter}
                            onSelect={(item)=>onSessionTypeFilterSelection(item)}
                            >
                                <div className='session-type-filter'>
                                    <span className='inter-semibold-10-18' style={{color: '#000', flex: 4}}>{selectedSessionType}</span>
                                    <div style={{flex: 1}}>
                                    {
                                        showSessionTypeFilter ? <UpArrow />: <DownArrow />
                                    }
                                    </div>
                                </div>
                            </PopoverDropdown>

                        </div>

                        <Divider />

                        <div className="card-tile-container margin-horizontal-32">
                        
                            {
                                statisticCardData.map((item, idx) => {
                                    return <StatisticCard key={idx} value={item}/>
                                })
                            }
                        </div>

                        <div className="filtered-candidate-card-container margin-horizontal-32 session-list-container">

                            <div className='sticky-filter-and-pagination'>
                                <div className="filtered-candidate-top-section d-flex justify-content-between align-items-center">
                                    <div className="filtered-candidate-tab-container d-flex">
                                        <div 
                                        className={`filtered-candidate-single-tab filtered-candidate-active-tab`}
                                        >
                                            {selectedSessionType}
                                        </div>
                                    </div>
                                    {/* <div className="filtered-candidate-options d-flex">
                                        <PopoverDropdown
                                        content={filterData}
                                        visible={showFilter}
                                        setVisible={setShowFilter}
                                        onSelect={(item)=>onFilter(item)}
                                        >
                                            <div className="single-option-container d-flex align-items-center">
                                                <FilterIcon />
                                                <span className='single-option-text'>Filter</span>
                                            </div>
                                        </PopoverDropdown>
                                    </div> */}
                                    <div className="session-list-pagination-container d-flex justify-content-end">
                                        {
                                            totalItems ? (
                                                <NewCustomPagination
                                                    totalItems={totalItems}
                                                    totalItemsPerPage={totalItemsPerPage}
                                                    currentPage={currentPage}
                                                    onPageChange={handlePageChange}
                                                />
                                            ): null
                                        }
                                    
                                    </div>
                                </div>

                                {/* <div className="session-list-pagination-container d-flex justify-content-end mt-16">
                                    <NewCustomPagination
                                        totalItems={totalItems}
                                        totalItemsPerPage={totalItemsPerPage}
                                        currentPage={currentPage}
                                        onPageChange={handlePageChange}
                                    />
                                </div> */}
                            </div>
                                
                            <div className="filtered-candidate-cards d-flex">
                                
                                {
                                    sessionListLoading ? (<>
                                        <Skeleton active/>
                                    </>):
                                    sessionListData?.length ? sessionListData.map((item,idx) => {
                                        return <SessionCard key={idx} item={item}/>
                                    }): null
                                }
                            
                            </div>
                            

                        </div>
                    </>
                )
            }

        <SessionCreationFloaterButton 
            isSessionCreationAllowed={true} // needs binding from API
            showButton={false}
            showMenu={showFloaterMenu}
            setShowFloaterMenu={()=>setShowFloaterMenu(!showFloaterMenu)}
        />
        </div>
        <ScrollToTopButton scrollContainerRef={scrollContainerRef}/>
    </>
  )
}

export default SessionListV2